<template>
<b-container fluid class="h-100 px-0" style="min-height:100vh;">
  <div class="content-logins h-100" style="min-height:100vh;">
    <b-row cols-lg="3" cols-md="2"  cols-sm="2" class="justify-content-center h-100 mx-0">
      <b-col align-self="center">
          <b-img
            style="width: 40px;height: 40px; position: absolute; right: 1rem; top: 1rem;"
            alt="Title logo"
            :src="require('@/assets/icon-back-x.png')"
            @click="goTo('play')"
          ></b-img>
        <b-card class="card-login">
          <b-img
            fluid
            alt="Title logo"
            :src="require('@/assets/login-logo.png')"
            class="logo-login"
          ></b-img>
          <!-- <h4 class="txt-white mt-4">Verifikasi OTP</h4> -->
          <span class="txt-white d-block mt-3 font-weight-bold" style="font-size: 1.1rem;">Mainkan gamenya dan dapatkan hadiah-hadiahnya!</span>
        </b-card>
        <b-card-body class="card-login py-1 px-5">
          <!-- <b-img
            fluid
            alt="Title logo"
            :src="require('@/assets/pesta.png')"
          ></b-img> -->
        </b-card-body>
        <!-- <b-card-body class="card-login py-1 px-5">
          <b-img
            fluid
            alt="Title logo"
            :src="require('@/assets/hadiah.png')"
          ></b-img>
        </b-card-body> -->
        <!-- <b-card-body class="card-login py-1 px-5">
          <b-img
            fluid
            alt="Title logo"
            :src="require('@/assets/reward.png')"
          ></b-img>
        </b-card-body> -->
      </b-col>
    </b-row>
  </div>
</b-container>
</template>

<script>
import { mapActions } from 'vuex'
// import { apiPortal } from '@/provider'
export default {
    data() {
        return {
            msisdn : this.$store.state.user,
            // token : null,
            // appkey:  this.$store.state.appKey,
            form : {
                otp : null
            }
        }
    },
    mounted() {
      this.$store.state.isLoading = true
        this.getProfile().then(() => {
          this.goTo('play')
        }, error => {
          console.error('err', error);
        })
    },
    methods : {
      ...mapActions([
        'getProfile'
      ]),
      goTo(page) {
          this.$router.push({ name: page });
      },
    }
}
</script>

<style scoped>
    .content-logins{
      background-color: #005fff;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
    }
    .card-login{
      background-color: transparent;
      border: none;
      transform: translate(0%, 9%);
      -webkit-transform: translate(0%, 9%);
    }
    .txt-white {
        color: white;
    }
    #form-input-otp {
      padding-left: 15px;
      letter-spacing: 42px;
      border: 0;
      background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 50px 1px;
      background-repeat: repeat-x;
      background-position-x: 35px;
      width: 220px;
    }
    .logo-login{
      width:100%;
      max-width: 20rem;
      padding: 2rem;
      padding-bottom: 0.5rem;
    }
    /* .max-35{
        max-width: 350px;
    } */
</style>
